import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AesService } from 'src/app/services/aes/aes.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service'
import { UserService } from 'src/app/services/user/user.service';
import * as _ from 'lodash';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { MessageService } from 'primeng/api';
import { environment } from '../../../environments/environment';
declare var gtag:any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss', './login.component.mobile.scss'],
  providers: [MessageService]
})
export class LoginComponent implements OnInit {
  otpModelVisible: boolean = false;
  newUser=false;
  loginHeaderText: string = 'Welcome to Assure by BluSmart!';
  loginSubHeaderText: string = 'Sign up to get started or log in if already an existing user';
  signInLinkText: string = 'Sign up now';
  signInLinkLabelText: string = `Don't have an account? `;
  enableContinueButton: boolean = false;
  isDesktopView = true;

  constructor(private userService: UserService, private authenticationService: AuthenticationService, private router: Router, private route: ActivatedRoute, private responsiveService: ResponsiveService, private messageService: MessageService) {
  }
  dropdownOptions={}
  loginError:string = '';
  checked =false;
  email: any;
  userId:any;
    
  ngOnInit() {
    this.responsiveService.isDesktopScreen().subscribe(isDesktop => {
      this.isDesktopView = isDesktop;
    });
    this.email =null;

    this.route.queryParams.subscribe((params) => {
      if (params['source'] === 'signup') {
        this.loginHeaderText = 'Welcome to Assure by BluSmart!';
        this.loginSubHeaderText = 'Sign up to get started or log in if already an existing user';
        this.signInLinkLabelText = 'Already have an account? ';
        this.signInLinkText = 'Sign in now';
      }
    });

   }


  validateUserForLogin(resendOtp = false){
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(this.email)) {
      Swal.fire({
        title: "Error",
        icon: "error",
        text: "Please enter email address",
      });
      return;
    }
    let data:any ={
      // "notificationChannel": "EMAIL",
      "email": this.email
  }
  if(resendOtp)
    data['resendOtp']= true;
    
  this.authenticationService.login(data).subscribe((result:any)=>{
        if(result.statusCode >= 300){
          Swal.fire({
            title: "Error",
            icon: "error",
            text: result['message'] || "Login failed",
          });
        }else{
          this.userId = result.response.userId;
          this.newUser = result.response.newUser;
          // Swal.fire({
          //   icon:"success",
          //   title:"Success",
          //   text:"OTP sent successfully"
          // }).then(()=>{
          if (!environment.production) {
            this.messageService.add({ severity: 'success', summary: 'OTP', detail: result.response.otp });
          }
            this.otpModelVisible = true;
          // })
          
        }
    },(error)=>{
      console.log("Error validating user while login");
      Swal.fire({
        title: "Error",
        icon: "error",
        text:  "Technical Error",
      });
    });
  
  }
  debouncedValidateEmail=  _.debounce(this.validateEmail,500)
  validateEmail() {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;
    this.enableContinueButton = emailRegex.test(this.email);
    if(this.enableContinueButton){
      gtag('event', 'ASSURE_APP_LOGIN_INITIATED', {
        'event_category': 'LOGIN_FORM_INITIATED',
        'event_label': 'Login form initiated',
        'value': 'User entered email id'   
      })
    }
  }
  validateEmailOnPaste(event: ClipboardEvent) {
    if (event.clipboardData?.getData('text')) {
      event.preventDefault();
      this.email = event.clipboardData?.getData('text');
      this.validateEmail()
    }
  }
    responseFromOtpModal(otpRes:any){
      this.otpModelVisible = false;
      if(otpRes['isOtpVerified'] && otpRes['ssoDetails'] != undefined && otpRes['ssoDetails']['access_token'] && !otpRes['isNewUser']){
        this.userService.setAuthToken(otpRes['ssoDetails']['access_token']);
        this.userService.setAccessToken(otpRes['ssoDetails']['access_token']);
        this.userService.setFirstName(otpRes['firstName']);
        this.userService.setLastName(otpRes['lastName']);
        this.userService.setUserId(otpRes['userId']);

        localStorage.setItem('authToken',otpRes['ssoDetails']['access_token']);
        localStorage.setItem('accessToken',otpRes['ssoDetails']['access_token']);
        localStorage.setItem('firstName',otpRes['firstName']);
        localStorage.setItem('lastName',otpRes['lastName']);
        localStorage.setItem('userId',otpRes['userId']);

        gtag('event', 'ASSURE_APP_LOGIN_SUCCESS', {
          'event_category': 'LOGIN_SUCCESS',
          'event_label': 'Successfully logged-in',
          'value': 'User successfully logged-in'   
        })

        this.router.navigateByUrl('/dashboard/assets');
      }
      if(otpRes['isOtpVerified'] && otpRes['isNewUser']){
        this.userService.setUserEmailId(this.email);
        this.userService.setUserId(otpRes['userId'])

        gtag('event', 'ASSURE_APP_EMAIL_OTP_REQUESTED', {
          'event_category': 'LOGIN_FORM_INITIATED',
          'event_label': 'Login form initiated',
          'value': 'User requested for login/signup OTP'   
        })

        this.router.navigateByUrl('/auth/signup');
        
      }

    }

    resendOtpClicked(){
      this.validateUserForLogin(true);
    }

}
